import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { DataService } from '../data.service';
import { ContactDto } from '../models/contact-dto';
import { SettingsService } from '../settings.service';

export enum FormState {
  Start = 1,
  Success = 2,
  Fail = 3
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  public contactData = {} as ContactDto;
  public formState: FormState = FormState.Start;
  public sending = false;
  public siteKey: string;

  constructor(private location: Location, private dataService: DataService, private settings: SettingsService) {
    this.siteKey = this.settings.settings.recaptchaKey;
  }

  ngOnInit(): void {}

  public capchaResolved(captchaResponse: string) {
    this.contactData.captchaResponse = captchaResponse;
  }

  public sendMessage() {
    this.sending = true;
    this.dataService
      .sendMessage(this.contactData)
      .pipe(
        catchError(() => of(FormState.Fail)),
        take(1)
      )
      .subscribe((x) => {
        if (typeof x === 'boolean') {
          this.formState = x ? FormState.Success : FormState.Fail;
        } else {
          this.formState = x;
        }

        this.sending = false;
      });
  }

  public ok(): void {
    this.location.back();
  }

  public reset(): void {
    this.formState = FormState.Start;
  }

  public resolved(captchaResponse: string): void {
    this.contactData.captchaResponse = captchaResponse;
  }

  public getReady(): boolean {
    return (
      !!this.contactData.name &&
      this.contactData.name.trim().length > 0 &&
      !!this.contactData.email &&
      this.contactData.email.trim().length > 0 &&
      !!this.contactData.message &&
      this.contactData.message.trim().length > 0 &&
      !!this.contactData.captchaResponse &&
      this.contactData.captchaResponse.trim().length > 0
    );
  }
}
