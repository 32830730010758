import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContactComponent } from './contact/contact.component';
import { PageNotFoundComponent } from './framework/page-not-found.component';
import { ShellComponent } from './framework/shell/shell.component';
import { HomeComponent } from './home/home.component';
import { LinksComponent } from './links/links.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: ShellComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'links', component: LinksComponent },
      { path: 'contact', component: ContactComponent },
      {
        path: 'gallery-stories',
        loadChildren: () => import('./gallery-stories-module/gallery-stories.module').then((m) => m.GalleryStoriesModule)
      },
      { path: '**', component: PageNotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
