<div class="shadow-box">
  <div class="d-flex justify-content-between">
    <div class="h3" style="margin: 4px">Contact</div>
  </div>
</div>
<div *ngIf="formState == 1" class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
  <form>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">* Name:</label>
      <div class="col-sm-8">
        <input class="form-control" [(ngModel)]="contactData.name" name="name" [disabled]="sending" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">* Email Address:</label>
      <div class="col-sm-8">
        <input class="form-control" [(ngModel)]="contactData.email" name="email" [disabled]="sending" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">* Message:</label>
      <div class="col-sm-8">
        <textarea class="form-control" [(ngModel)]="contactData.message" name="message" rows="5" [disabled]="sending"></textarea>
      </div>
    </div>
    <div class="form-group row">
      <div class="offset-sm-4 col-sm-8">
        <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
      </div>
    </div>
    <div class="form-group row">
      <div class="offset-sm-4 col-sm-8">
        <button [disabled]="!getReady() || sending" class="btn btn-success" type="button" (click)="sendMessage()">Send Message</button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="formState == 2" class="col-12">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-success" role="alert">
        <p class="text-center mb-0" style="font-size: larger; font-weight: bold">
          Thank your for contacting Timothy Blue<br />Your message has been sent.
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <button type="button" class="btn btn-success" (click)="ok()">OK</button>
    </div>
  </div>
</div>
<div *ngIf="formState == 3" class="col-12">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" role="alert">
        <p class="text-center mb-0" style="font-size: larger; font-weight: bold">
          It looks like something went wrong.<br />Please try again later
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 text-center">
      <button type="button" class="btn btn-success" (click)="reset()">Try Again</button>
    </div>
  </div>
</div>
