<div class="carousel-container">
  <div class="carousel">
    <ng-container *ngFor="let slide of slides; let i = index">
    <img *ngIf="i === currentSlide" [src]="slide" class="slide" @carouselAnimation />
  </ng-container>
  </div>
  <div class="controls">
    <div *ngFor="let slide of slides; let i = index" [class.active]="i === currentSlide" (click)="goto(i)"></div>
  </div>
</div>
