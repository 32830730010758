<div class="d-flex flex-column h-100">
  <div class="shadow-box">
    <div class="d-flex">
      <div class="h3" style="margin: 4px">Links</div>
    </div>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item pointer" (click)="followLink('https://bigalcle.com')">
      <h5 class="font-weight-bold mb-1">Big Al and the Hitmen</h5>
      <small>My 3 piece cover band</small>
    </li>
    <li class="list-group-item pointer" (click)="followLink('https://www.intellifactor.com')">
      <h5 class="font-weight-bold mb-1">Intellifactor</h5>
      <small>My Android app company</small>
    </li>
    <li class="list-group-item pointer"  (click)="followLink('https://instagram.com/timothyjblue')">
      <h5 class="font-weight-bold mb-1">Instagram</h5>
      <small>Follow me on Instagram</small>
    </li>
  </ul>
</div>
