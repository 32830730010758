<nav class="navbar navbar navbar-dark bg-dark navbar-expand-md fixed-top">
  <div class="navbar-brand" href="#">
    <img class="d-none d-sm-block" src="../../../assets/images/timothyblue.png" alt="Timothy Blue" width="217" height="50" />
    <img
      class="d-block d-sm-none d-md-none d-xl-none d-xxl-none"
      src="./../../assets/images/timothyblue.png"
      alt="Timothy Blue"
      width="174"
      height="40"
    />
  </div>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    (click)="isNavbarCollapsed = !isNavbarCollapsed"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse d-flex-nowrap justify-content-end" id="navbarContent" [ngbCollapse]="isNavbarCollapsed">
    <ul class="navbar-nav align-items-end">
      <li>
        <a class="nav-link main-menu" routerLink="/home" routerLinkActive="active">Home</a>
      </li>
      <li
        class="nav-item main-menu dropdown"
        ngbDropdown
        id="userMenuDropdown"
        routerLinkActive="active"
      >
        <a class="nav-link pointerCursor" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          Gallery Stories
        </a>
        <div class="dropdown-menu dropdown-menu-dark dropdown-menu-right" aria-labelledby="userMenuDropdown" ngbDropdownMenu>
          <a class="dropdown-item main-menu" routerLink="/gallery-stories/la-marzocco" ngbDropdownItem>La Marzocco Rebuild</a>
        </div>
      </li>
      <li>
        <a class="nav-link main-menu" routerLink="/links" routerLinkActive="active">Links</a>
      </li>
      <li>
        <a class="nav-link main-menu" routerLink="/contact" routerLinkActive="active">Contact</a>
      </li>
    </ul>
  </div>
</nav>
