import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

export interface AppSettings {
  serviceUri: string;
  recaptchaKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public settings: AppSettings | any = {};

  constructor(private http: HttpClient) {}

  public initializeApp(): Promise<any> {
    return new Promise<void>((resolve) => {
      this.http
        .get<AppSettings>('assets/settings.local.json')
        .pipe(catchError(() => this.http.get<AppSettings>('assets/settings.json')))
        .toPromise()
        .then((response) => {
          this.updateSettings(response as AppSettings);
          resolve();
        });
    });
  }

  private updateSettings(appSettings: AppSettings) {
    Object.keys(appSettings)
      .forEach((key) => {
        const appSettingsKey = key as keyof AppSettings;
        (this.settings[appSettingsKey] as any) = appSettings[appSettingsKey]
      });
  }
}
