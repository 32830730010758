import { animate, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { Subject, timer } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.css'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition('* => void', [animate('300ms', style({ opacity: 0 }))])
    ])
  ]
})
export class SlideshowComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() public slides!: string[];

  private restartTimer = new Subject<void>();
  private destroy = new Subject<void>();

  public currentSlide = 0;

  public faSpinner = faSpinner;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterContentInit(): void {
    this.restartTimer
      .pipe(
        startWith(void 0),
        switchMap(() => timer(3000, 3000)),
        takeUntil(this.destroy)
      )
      .subscribe(() => this.gotoNext());
  }

  public goto(index: number) {
    this.restartTimer.next();
    this.currentSlide = index;
  }

  private gotoNext(): void {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides!.length ? 0 : next;
  }
}
