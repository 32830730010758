import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  public followLink(url: string): void {
    window.open(url, "_blank");
  }
}
