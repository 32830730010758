import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';
import { HttpInterceptor } from 'src/http-interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { DataService } from './data.service';
import { FooterComponent } from './framework/footer/footer.component';
import { MenuComponent } from './framework/menu/menu.component';
import { ShellComponent } from './framework/shell/shell.component';
import { HomeComponent } from './home/home.component';
import { LinksComponent } from './links/links.component';
import { SettingsService } from './settings.service';
import { SlideshowComponent } from './slideshow/slideshow.component';

export function app_Init(settingsService: SettingsService) {
  return () => settingsService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    SlideshowComponent,
    HomeComponent,
    ShellComponent,
    MenuComponent,
    FooterComponent,
    LinksComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    RecaptchaModule,
    FontAwesomeModule,
    NgbModule
  ],
  providers: [
    DataService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: app_Init, deps: [SettingsService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
