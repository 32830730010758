import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactDto } from './models/contact-dto';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private serviceUri: string;

  constructor(private http: HttpClient, private settings: SettingsService) {
    this.serviceUri = this.settings.settings.serviceUri;
  }

  public sendMessage(contactInfo: ContactDto): Observable<boolean> {
    const body = JSON.stringify(contactInfo);

    return this.http.post<boolean>(`${this.serviceUri}/contact/email`, body);
  }
}
