<div class="d-flex align-items-center justify-content-center">
  <div style="width: 940px; height: 425px">
    <app-slideshow [slides]="slides"></app-slideshow>
    <div class="mt-5">
      <p>
        I have always been interested in many different hobbies. Most of these hobbies revolve around some sort of technology or machinery.
      </p>
      <p>I also have a hard time sitting still.</p>
      <p>The combination of the interest and the mania have produced what I consider to be some pretty interesting projects.</p>
      <p>This web site is an attempt to conglomerate my different interests into one place.</p>
    </div>
  </div>
</div>
