import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public slides = [
    '/assets/images/slideshow/big_parts.jpg',
    '/assets/images/slideshow/boiler.jpg',
    '/assets/images/slideshow/captive_nuts.jpg',
    '/assets/images/slideshow/guage.jpg',
    '/assets/images/slideshow/machine.jpg',
    '/assets/images/slideshow/parts.jpg'
  ];

  constructor() {}

  ngOnInit(): void {}
}
