import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface VersionInfo {
  version: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public versionInfo: Observable<string> | undefined;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.versionInfo = this.getversion();
  }

  public get year(): string {
    return new Date().getFullYear().toString();
  }

  public getversion(): Observable<string> {
    return this.http.get<VersionInfo>('assets/version.json').pipe(
      catchError(() => of({ version: 'dev' })),
      map((x) => x.version)
    );
  }
}
